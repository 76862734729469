<template>
  <Top />
  <div class="main" v-loading="faceRecord.loading">
    <transaction-navbar
      :title="lang.v.transactionDetails"
      :CancelPedding="route.query?.PeddingOrder == 1"
      :CancelOrder="route.query?.PeddingOrder == 2"
      :version="faceRecord?.transactionGoods?.version"
    />
    <template v-if="route.query?.PeddingOrder == 2">
      <div class="order-tips" v-if="!faceRecord.transactionGoods?.normal">
        <div>
          <span class="title" style="color: #f00">{{ lang.v.OrderErr }}</span>
          <div class="order-time">
            <span class="tips">{{ lang.v.CustomeRservice }}</span>
          </div>
        </div>
      </div>
      <div
        class="order-tips"
        v-else-if="faceRecord.orderStatus == 0 || faceRecord.orderStatus == 1"
      >
        <div>
          <span class="title">{{ lang.v.orderGenerated }}</span>
          <div class="order-time">
            <span class="tips">{{ lang.v.please }}</span>
            <span class="time">{{ orderTime }}</span>
            <span class="tips">{{ lang.v.paymentInformation }}</span>
          </div>
        </div>
        <div>
          <button class="err" @click="open">
            {{ lang.v.reportExceptions }}
          </button>
        </div>
      </div>
      <div class="order-tips" v-else-if="faceRecord.orderStatus == 2">
        <div>
          <span class="title">{{ lang.v.OrderOverTime }}</span>
          <div class="order-time">
            <span class="tips">{{ lang.v.CustomeRservice }}</span>
          </div>
        </div>
      </div>
      <div class="order-tips" v-else-if="faceRecord.orderStatus == 4">
        <span class="title">{{ lang.v.sellerToConfirm }}</span>
        <div class="order-time">
          <span class="tips">{{ lang.v.ordersCannotStage }}</span>
        </div>
      </div>
      <div class="order-tips" v-else-if="faceRecord.orderStatus == 6">
        <span class="title">{{ lang.v.orderCompleted }}</span>
        <div class="order-time">
          <span class="tips">{{ lang.v.contactustomer }}</span>
        </div>
      </div>
    </template>

    <div class="user">
      <div class="user-left">
        <img src="~@/assets/img/Ellipse.png" alt="" class="avatar" />
        <span class="nickname">{{ lang.v.anonymousUser }}</span>
      </div>
      <div class="user-right">
        <div>{{ lang.v.deal }} {{ faceRecord.dealCount }}</div>
      </div>
    </div>
    <payment :type="faceRecord.type" disabled="true" />
    <points-detailed
      :type="buySell"
      :transactionGoods="
        PeddingOrder
          ? faceRecord.transactionGoods
          : faceRecord.transactionGoods.commodityVO
      "
    ></points-detailed>
    <Message-board
      v-if="route.query.PeddingOrder == 2"
      :orderData="faceRecord.transactionGoods"
    />
  </div>
  <transaction-submitv2
    :peddingOrder="route.query.PeddingOrder"
    :orderStatus="faceRecord.orderStatus"
    :required="getTransactionOrder"
    :orderData="faceRecord.transactionGoods"
  />
</template>
<script setup>
import { useRoute } from 'vue-router';
import Top from '@/components/Top/top.vue';
import { reactive, onMounted, ref, computed, onUnmounted } from 'vue';
import {
  fetchTransactionServeGoods,
  fetchTransactionServeNumber,
  fetchOrderInfo,
  fetchErrOrder,
} from '@/api/fetcher';
import TransactionNavbar from '@/components/TransactionNavbar/index.vue';
import { ElMessageBox } from 'element-plus';
import payment from '@/components/Payment/index.vue';
import dataCenter from '@/dataCenter';
import { lang } from '@/lang';
import PointsDetailed from '@/components/PointsDetailed/index.vue';
import MessageBoard from '@/components/MessageBoard/MessageBoard.vue';
import TransactionSubmitv2 from '@/components/TransactionSubmitv2/index.vue';
const orderTime = ref('0');
const route = useRoute();
const PeddingOrder = ref(false);
const faceRecord = reactive({
  /** 买卖单列表 */
  transactionGoods: {},
  loading: false,
  /** 商品类型 1：买 2：卖 */
  type: 2,
  /** 支付方式 */
  payWay: null,
  // 支付方式名称
  payWayName: '',
  /** 成交单数 */
  dealCount: 0,
  /** 支付备注 */
  payDesc: '',
  /** 订单状态 */
  orderStatus: 0,
  /** 禁止提交订单 */
  isClickDisabled: false,
});
// 获取商品信息
const getTransactionGoods = async () => {
  const data = await fetchTransactionServeGoods({
    id: route.query.id,
  });
  if (data) {
    faceRecord.transactionGoods = data;
    const foundItem = dataCenter.payStatus.findDataPayStatus(route.query.id);
    if (!foundItem?.payWay) {
      faceRecord.isClickDisabled = true;
    }
  }
};
// 获取订单信息
const getTransactionOrder = async () => {
  const data = await fetchOrderInfo({
    id: route.query.id,
  });
  if (data) {
    faceRecord.transactionGoods = data;
    faceRecord.orderStatus = data.status;
    faceRecord.createTime = data.updateTime;
    const foundItem = dataCenter.payStatus.findDataPayStatus(route.query.id);
    if (!foundItem?.payWay) {
      faceRecord.isClickDisabled = true;
    }
  }
};
// 买卖
const buySell = computed(() => {
  if (faceRecord.transactionGoods?.type == 1) {
    if (
      faceRecord.transactionGoods?.buyUser ==
      JSON.parse(localStorage.getItem('userInfo'))?.userId
    ) {
      return 1;
    } else {
      return 2;
    }
  } else {
    if (
      faceRecord.transactionGoods?.buyUser ==
      JSON.parse(localStorage.getItem('userInfo'))?.userId
    ) {
      return 1;
    } else {
      return 2;
    }
  }
});
// 上报异常
const open = async () => {
  const confirmResult = await ElMessageBox.confirm(
    lang.v.reportCorrect,
    'Warning',
    {
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel',
      type: 'warning',
    }
  );
  if (confirmResult === 'confirm') {
    try {
      // 发送请求
      await fetchErrOrder({
        orderId: faceRecord.transactionGoods?.id,
        version: faceRecord.transactionGoods?.version,
      });
      getTransactionOrder();
    } catch (error) {
      console.log(error);
    }
  }
};
// 用户成交笔数
const getTransactionServeNumber = async () => {
  const data = await fetchTransactionServeNumber({
    userIds: faceRecord.transactionGoods.creator,
  });
  if (data) {
    faceRecord.dealCount = data[faceRecord.transactionGoods.creator];
  }
};

// 计算和格式化时间
const formattedTime = () => {
  // 转换时限为毫秒
  const timeLimit = faceRecord.timeLimit * 60 * 1000;
  // 计算已经过去的时间
  const timeElapsed = Date.now() - new Date(faceRecord.createTime).getTime();
  // 计算剩余时间
  const timeRemaining = Math.max(timeLimit - timeElapsed, 0);
  const minutes = String(Math.floor(timeRemaining / 60000)).padStart(2, '0');
  const seconds = String(Math.floor((timeRemaining % 60000) / 1000)).padStart(
    2,
    '0'
  );
  return `${minutes}:${seconds}`;
};

// 设置定时器
let timer;
onMounted(async () => {
  faceRecord.type = route.query.type;
  if (route.query?.PeddingOrder == 1) {
    await getTransactionGoods();
    PeddingOrder.value = true;
  } else {
    await getTransactionOrder();
    PeddingOrder.value = false;
  }
  getTransactionServeNumber();
  orderTime.value = formattedTime();
  faceRecord.timeLimit = dataCenter.payStatus.timeLimitMinutes;
  timer = setInterval(() => {
    // 计算和更新剩余时间
    orderTime.value = formattedTime();
    if (orderTime.value == '00:00') {
      clearInterval(timer);
      getTransactionOrder();
    }
  }, 1000);
});
onUnmounted(() => clearInterval(timer));
</script>
<style scoped lang="less">
.main {
  padding: 0.14rem;
  background: #f6f6f8;
  overflow-y: scroll;
  .user {
    display: flex;
    margin-top: 0.17rem;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.12rem;
    height: 0.54rem;
    background: #ffffff;
    border-radius: 0.08rem;
    .user-left {
      .nickname {
        font-weight: 400;
        font-size: 0.14rem;
        color: #000000;
        line-height: 0.16rem;
      }
    }
    .user-right {
      font-weight: 400;
      font-size: 0.12rem;
      color: #858c9f;
      line-height: 0.18rem;
    }
  }
}
.avatar {
  margin-right: 0.05rem;
  width: 0.22rem;
  height: 0.22rem;
}
.order-tips {
  display: flex;
  justify-content: space-between;
  margin-top: 0.15rem;
  .err {
    color: #f00;
    border: 0.01rem solid #f00;
  }
  .title {
    font-weight: 600;
    font-size: 0.18rem;
    color: #222222;
    line-height: 0.21rem;
  }
  .order-time {
    margin-top: 0.11rem;
    font-weight: 400;
    .tips {
      font-size: 0.12rem;
      line-height: 0.14rem;
      color: #858c9f;
    }
    .time {
      margin: 0 0.06rem;
      font-size: 0.16rem;
      line-height: 0.19rem;
      color: #222222;
    }
  }
}
.order-tip {
  margin-top: 0.15rem;
  .err {
    color: #f00;
    border: 0.01rem solid #f00;
  }
  .title {
    font-weight: 600;
    font-size: 0.18rem;
    color: #222222;
    line-height: 0.21rem;
  }
  .order-time {
    margin-top: 0.11rem;
    font-weight: 400;
    .tips {
      font-size: 0.12rem;
      line-height: 0.14rem;
      color: #858c9f;
    }
    .time {
      margin: 0 0.06rem;
      font-size: 0.16rem;
      line-height: 0.19rem;
      color: #222222;
    }
  }
}
</style>
